import { useSwipe } from 'components/_hooks/useSwipe'
import { generateID } from 'components/_utils/idUtils'
import { Img } from 'components/basic/Img'
import { VideoGif } from 'components/basic/VideoGif'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import { Type } from 'components/Type'
import { amSeenProductBreakdown } from 'events/amplitude'
import { FC, useEffect, useRef, useState } from 'react'
import { useMetricRegion } from 'stores/settings'
import styles from './ProductBreakdown.module.scss'
import { MediaItem, ProductBreakdownItem, ProductBreakdownProps } from './ProductBreakdown.types'

export const ProductBreakdownConnected = (props: ProductBreakdownProps) => {
	const metric = useMetricRegion()

	return (
		<ProductBreakdown
			{...props}
			metric={metric}
		/>
	)
}

export const ProductBreakdown: FC<ProductBreakdownProps> = (props) => {
	const { header = 'Transform any bed with the Pod', subheader, cta, items = getDefaultItems(props.metric, props.theme || 'light'), theme = 'light' } = props

	const [selectedIndex, setSelectedIndex] = useState(0)
	const itemRefs = useRef<HTMLLIElement[]>([])
	const listRef = useRef<HTMLUListElement>(null)
	const wrapperRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handleFullIntersection = (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting && !window.sessionStorage.getItem('seen_product_breakdown')) {
					window.sessionStorage.setItem('seen_product_breakdown', 'true')
					amSeenProductBreakdown()
				}
			})
		}

		const observer = new IntersectionObserver(handleFullIntersection)
		observer.observe(wrapperRef.current)

		return () => {
			observer.disconnect()
		}
	}, [])

	useEffect(() => {
		const resizeHandler = () => {
			const items = itemRefs.current.filter((it) => it !== null)
			if (items.length === 0) return

			const itemHeights = items.map((it) => it.getBoundingClientRect().height)
			const totalHeight = itemHeights.reduce((acc, curr) => acc + curr, 0)

			listRef.current?.style.setProperty('--min-height', `${totalHeight * 1.02}px`)
		}

		resizeHandler()
		window.addEventListener('resize', resizeHandler)
		return () => {
			window.removeEventListener('resize', resizeHandler)
		}
	}, [selectedIndex])

	return (
		<section
			className={styles.wrapper}
			ref={wrapperRef}
			data-theme={theme}
		>
			<div className={styles.container}>
				<header>
					<Type.Headline2 animateOnScroll>{header}</Type.Headline2>
					<Type.Body1 animateOnScroll>{subheader}</Type.Body1>
				</header>

				<div className={styles.breakdown}>
					<ul
						className={styles.breakdown_list}
						ref={listRef}
					>
						{items.map((item, i) => (
							<li
								key={item.title}
								data-highlighted={i === selectedIndex}
								className={styles.breakdown_item}
								ref={(ref) => (itemRefs.current[i] = ref)}
							>
								<Button.Empty
									id={`product-breakdown-item-${generateID(item.title)}`}
									className={styles.item_button}
									onClick={() => setSelectedIndex(i)}
								>
									<BreakdownItem
										{...item}
										selected={i === selectedIndex}
									/>
								</Button.Empty>
							</li>
						))}
					</ul>
					{cta && (
						<div className={styles.cta}>
							<Button.White
								id={'product-breakdown-cta'}
								href={cta.href}
							>
								{cta.text}
							</Button.White>
						</div>
					)}
				</div>
				<div className={styles.media_container}>
					{items.map((item, i) => (
						<div
							key={`product-breakdown-media-${i}`}
							className={styles.media_stack}
							data-highlighted={i === selectedIndex}
						>
							<MediaStack
								{...item}
								theme={theme}
							/>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

const BreakdownItem: FC<ProductBreakdownItem & { selected: boolean }> = (props) => {
	const descriptionRef = useRef<HTMLSpanElement>(null)

	const [height, setHeight] = useState(0)

	useEffect(() => {
		const handleResize = () => {
			if (descriptionRef.current) {
				setHeight(descriptionRef.current.getBoundingClientRect().height)
			}
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		if (descriptionRef.current) {
			setHeight(descriptionRef.current.getBoundingClientRect().height)
		}
	}, [props.selected])

	return (
		<span className={styles.breakdown_item_content}>
			<Type.Headline5
				as={'p'}
				role="heading"
				aria-level={2}
			>
				{props.title}
			</Type.Headline5>
			<Type.Body2
				style={{
					maxHeight: props.selected ? `calc(${height}px + 0.5em)` : '0px',
					marginTop: props.selected ? `1rem` : '0px',
				}}
				className={styles.breakdown_item_description}
			>
				<span ref={descriptionRef}>{props.description}</span>
			</Type.Body2>
		</span>
	)
}

const MediaStack: FC<ProductBreakdownItem> = (props) => {
	const { media, title, theme } = props
	const [currentIndex, setCurrentIndex] = useState(0)
	const sliderRef = useRef<HTMLUListElement>(null)

	useSwipe(
		sliderRef,
		(direction: 'left' | 'right') => {
			if (direction === 'left') {
				setCurrentIndex((prev) => Math.min(media.length - 1, prev + 1))
			} else if (direction === 'right') {
				setCurrentIndex((prev) => Math.max(0, prev - 1))
			}
		},
		50,
		500
	)

	return (
		<>
			<ul
				className={styles.media}
				ref={sliderRef}
			>
				{media.map((media, i) => (
					<li
						key={`product-breakdown-media-${i}`}
						className={styles.media_item}
						data-highlighted={i === currentIndex}
					>
						<MediaRendered {...media} />
					</li>
				))}
			</ul>

			{currentIndex > 0 && (
				<Button.Empty
					id={'product-breakdown-prev'}
					className={styles.media_control_prev}
					onClick={() => setCurrentIndex((prev) => Math.max(0, prev - 1))}
					ariaLabel="Go to previous slide"
					role="button"
				>
					<Icon
						name={'ChevronLeftDark'}
						color={theme === 'dark' ? 'white' : 'black'}
						size={24}
					/>
				</Button.Empty>
			)}

			{media.length > 1 && currentIndex < media.length - 1 && (
				<Button.Empty
					id={'product-breakdown-next'}
					className={styles.media_control_next}
					onClick={() => setCurrentIndex((prev) => Math.min(media.length - 1, prev + 1))}
					ariaLabel="Go to next slide"
					role="button"
				>
					<Icon
						name={'ChevronRightDark'}
						color={theme === 'dark' ? 'white' : 'black'}
						size={24}
					/>
				</Button.Empty>
			)}

			{media.length > 1 && (
				<ul className={styles.dots}>
					{media.map((_, i) => (
						<li
							key={`product-breakdown-dot-${i}`}
							data-highlighted={i === currentIndex}
						>
							<Button.Empty
								id={`product-breakdown-dot-${generateID(title)}-${i}}`}
								className={styles.dot}
								onClick={() => setCurrentIndex(i)}
								ariaLabel={`Go to slide ${i + 1}`}
								role="button"
							>
								<div className={styles.dot_inner} />
							</Button.Empty>
						</li>
					))}
				</ul>
			)}
		</>
	)
}

const MediaRendered: FC<MediaItem> = (props) => {
	switch (props.type) {
		case 'image':
			return (
				<Img
					src={props.data.src}
					alt={props.data.alt}
					className={styles.media_image}
				/>
			)
		case 'video-gif':
			return (
				<VideoGif
					src={props.data.src}
					className={styles.media_video}
				/>
			)
	}
}

export const getDefaultItems = (metric: boolean, theme: ProductBreakdownProps['theme']): ProductBreakdownItem[] => {
	return [
		{
			title: 'Cover',
			description:
				'A high-tech layer that wraps around your mattress, similar to a fitted sheet. It facilitates automatic heating and cooling of the Pod, integrating health-grade sensors that track your heart rate and breathing rate, while you sleep.',
			media: [
				{
					type: 'video-gif',
					data: {
						src:
							theme === 'dark'
								? 'https://res.cloudinary.com/eightsleep/video/upload/t_video-gif/product_breakdown_cropped_kcdw8r.mp4'
								: 'https://res.cloudinary.com/eightsleep/video/upload/t_video-gif/product-breakdown-slide-1.1_r7zgux.mp4',
					},
				},
			],
		},
		{
			title: 'Base',
			description:
				'An adjustable Base that works seamlessly with the Pod to automatically reduce snoring. Fitting between your mattress and bed frame, the Base also offers custom positions for reading, relaxing, and sleeping.',
			media: [
				{
					type: 'image',
					data: {
						src: 'https://res.cloudinary.com/eightsleep/image/upload/product-breakdown-slide-2.1_jahuy8.png',
						alt: 'Eight Sleep Base',
					},
				},
				{
					type: 'image',
					data: {
						src: 'https://res.cloudinary.com/eightsleep/image/upload/product-breakdown-slide-2.2_cshik4.png',
						alt: 'Eight Sleep Base',
					},
				},
				{
					type: 'image',
					data: {
						src: 'https://res.cloudinary.com/eightsleep/image/upload/product-breakdown-slide-2.3_olm1za.png',
						alt: 'Eight Sleep Base',
					},
				},
			],
		},
		{
			title: 'Hub',
			description: `The Hub is the central system of the Pod that holds the water for cooling and heating and also the connectivity to the app. It enables Autopilot to take actions in real-time, all while fitting next to your nightstand.`,
			media: [
				{
					type: 'image',
					data: {
						src: 'https://res.cloudinary.com/eightsleep/image/upload/slide-3.1_ijgypi.png',
						alt: 'Eight Sleep Hub',
					},
				},
			],
		},
	]
}
