import React, { FC } from 'react'
import cx from 'classnames'
import styles from './Financing.module.scss'
import { PriceManager } from 'prices'
import { AffirmLogo } from 'components/_const_assets/affirmLogo'
import { KlarnaLogo } from 'components/_const_assets/klarnaLogo'
import { useCurrency, usePod4Prices } from 'stores/prices'
import { useCurrentRegion } from 'stores/settings'

export interface FinancingProps {
	monthlyPriceFormatted: string
	maxMonthsAtZeroInterest: number
	isPrimary?: boolean
	ctaHref?: string
	anchorLink?: boolean
}

export interface FinancingInfoProps {
	price?: number
	priceFormatted?: string
	className?: string
	noLogo?: boolean
	customLogoClass?: string
	color?: 'black' | 'white'
}

interface FinancingButtonProps {
	text: string
	price?: string
	className?: string
	noArrow?: boolean
}

export const AffirmButton: FC<FinancingButtonProps> = (props) => {
	return (
		<a
			className={cx(styles.animated_arrow, 'affirm-site-modal', styles.prequalify, props.className)}
			data-provider="Affirm"
			data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"
			role="button"
			tabIndex={0}
			aria-label="Pre qualify button for affirm"
		>
			{props.noArrow ? (
				props.text
			) : (
				<>
					<span className={cx(styles.the_arrow, styles.pos_left)}>
						<span className={styles.shaft} />
					</span>
					<span className={styles.main}>
						<span className={styles.text}>{props.text}</span>
						<span className={cx(styles.the_arrow, styles.pos_right)}>
							<span className={styles.shaft} />
						</span>
					</span>
				</>
			)}
		</a>
	)
}

export const FinancingInfo: FC<FinancingInfoProps> = (props) => {
	const currency = useCurrency()
	const region = useCurrentRegion()

	const { priceNumber, priceFormatted } = usePod4Prices()

	if (!priceFormatted) return null

	if (region === 'ca' || region === 'us' || region === '') {
		const financingAmount = PriceManager.getFinancingAmount(priceNumber, region)
		return (
			<a
				className={cx(styles.affirm_link, props.className, 'affirm-site-modal')}
				data-provider="Affirm"
				data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"
				role="button"
				tabIndex={0}
				aria-label="Pre qualify button for affirm"
			>
				<span>or as low as {PriceManager.formatPriceToCurrencyNoDecimal(financingAmount, currency)}/mo with </span>
				<AffirmLogo
					className={props.customLogoClass || 'affirmTransform'}
					textColor={props.color}
				/>
			</a>
		)
	} else if (region === 'uk' || region === 'eu') {
		return (
			<span className={cx(props.className, styles.klarna_link)}>
				or pay in 3 with{' '}
				<KlarnaLogo
					color={props.color || 'black'}
					className={cx(styles.klarna_logo, props.customLogoClass)}
					key={'fin'}
				/>
			</span>
		)
	} else {
		return <></>
	}
}
